@import '../util';

.installationView {
  background-color: #000000;
  text-align: center;

  &__linkContainer {
    padding-bottom: $mobile-gutter;
  }

  &__link {
    display: block;
    color: white;
    @extend %heading-2;

    &:hover {
      color: #ff2e54;
    }
  }
}