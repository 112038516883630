@import '../util';

.contact {
  padding: $mobile-gutter;
  background-color: white;

  @include respond-to(small) {
    padding: $desktop-gutter;
  }

  &__body {
    margin-bottom: 20px;
  }

  input[type="email"], textarea {
    display: block;
    font-family: Lato, sans-serif;
    font-size: 1rem;
    box-sizing: border-box;
    border: 1px solid #CCC;
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
  }

  input[type="submit"] {
    font-family: Lato, sans-serif;
    padding: 10px 40px;
    border: 1px solid #CCC;
    background-color: white;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      color: white;
      background-color: #4e87e4;
      border: 1px solid transparent;
    }
  }

  h1 {
    @extend %heading-2;
  }
}