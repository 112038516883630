@import '../util';

.one-column-gallery {
  padding: $mobile-gutter;
  background-color: black;
  min-height: 100%;
  box-sizing: border-box;

  @include respond-to(small) {
    padding: $desktop-gutter;
  }

  &__row {
    display: flex;
    justify-content: center;

    &:last-child {
      margin-bottom: 200px;

      @include respond-to(small) {
        margin-bottom: 220px;
      }
    }
  }

  &__overlay {
    position: absolute;
    z-index: 200;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: $mobile-gutter * 2;
    max-height: calc(100vh - #{$mobile-gutter * 2} - #{$mobile-nav-height});

    @include respond-to(small) {
      max-height: calc(100vh - #{$desktop-gutter * 2});
    }
  }

  &__image {
    object-fit: contain;
    overflow: hidden;
  }
}