@import '../util';

.home {
  width: 100%;
  height: 100%;
  background-color: black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__caption {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: white;
  }

  &__wrapper {
    flex: 0 1 100%;
    overflow: hidden;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__image {
    object-fit: contain;
    height: 100%;
  }

  &__image-description {
    font-weight: 400;
  }
}