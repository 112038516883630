@import '../util';

.info {
  position: relative;
  max-width: 100%;
  margin: #{$mobile-gutter / 2} auto 0;
  font-size: 0.875rem;
  text-align: center;
  color: rgba(231,234,237, 0.95);

  p {
    line-height: 1.5rem;
    margin: 0 0 8px;
  }

  span {
    white-space: nowrap;
    display: inline-block;
  }

  &__title {
    font-weight: bold;
  }

  &__text {
    &--inline {
      display: inline;
    }
  }

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      color: #ff2e54;
    }
  }

  &__dimension {
    white-space: nowrap;
  }
}