@import 'util';

.main {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include respond-to(small) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__body {
    flex: 1 0;
    min-width: 0;

    @include respond-to(small) {
      flex: 0 1 100%;
      height: 100%;
    }
  }
}

// Only add padding if nav is visible
.nav + .main {
  &__body {
    @include respond-to(small) {
      @media (min-height: $nav-height-extra-small) {
        margin-left: $nav-width;
      }
    }
  }
}