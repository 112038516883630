@import '../util';

.nav {
  flex: 0;
  padding: $mobile-gutter;

  @include respond-to(small) {
    flex: 0 0 150px;
    padding: $desktop-gutter;
    transition: padding 150ms ease-in-out;

    @media (min-height: $nav-height-extra-small) {
      // If it doesn't fit, we must scroll
      position: fixed;
      width: $nav-inner-width;
    }

    @media (max-height: $nav-height) {
      padding: $mobile-gutter $desktop-gutter;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: $mobile-gutter / 2;
    padding: $mobile-gutter / 2;
    font-size: 1.875rem;
    cursor: pointer;

    @include respond-to(small) {
      margin: $desktop-gutter / 2;
      padding: $desktop-gutter / 2;
    }
    
    &:hover {
      color: rgba(17, 17, 17, 0.6);
    }

    @include respond-to(small) {
      display: none;
    }

    &--menu {
      color: #878787;

      &:hover {
        color: rgba(17, 17, 17, 1);
      }
    }
  }

  &__title {
    @extend %heading-1;
    color: #111;
    white-space: pre-wrap;
    display: block;
    transition: font-size 150ms ease-in-out, line-height 150ms ease-in-out;

    @include respond-to(small) {
      width: auto;

      @media (max-height: $nav-height) {
        font-size: 40px;
        line-height: 44px;
      }
    }
  }

  &__menu-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px $desktop-gutter $desktop-gutter;
    transform: translateX(100vw);
    background-color: white;
    z-index: 300;
    transition: transform 150ms ease-in-out;

    &--active {
      transform: translateX(0);
    }

    @include respond-to(small) {
      position: relative;
      padding: 0;
      transform: none;
      transition: none;
      background-color: transparent;
    }
  }

  &__body--open {
    overflow: hidden;

    @include respond-to(small) {
      overflow: visible;
    }
  }

  &__menu {
    list-style-type: none;
    margin: 25px 0 0;
    transition: margin 150ms ease-in-out, font-size 150ms ease-in-out;
    padding: 0;
    font-size: 1.125rem;
    font-weight: 300;

    &--minor {
      font-size: 1rem;
    }

    @include respond-to(small) {
      @media (max-height: $nav-height) {
        margin-top: 20px;
        font-size: 1rem;

        &:not(:first-of-type) {
          margin-top: 15px;
        }
      }
    }
  }

  &__section {
    margin-bottom: 20px;
    transition: margin 150ms ease-in-out;

    &--text {
      cursor: default;
    }

    @media (max-height: $nav-height) {
      margin-bottom: 15px;
    }
  }

  &__subsection {
    padding-left: 10px;
    margin-bottom: 10px;
    transition: margin 150ms ease-in-out;

    @media (max-height: $nav-height) {
      margin-bottom: 10px;
    }
  }

  &__section, &__subsection {
    color: #878787;

    a {
      color: #878787;
    }


    &--active {
      a {
        color: #4e87e4;
      }
    }

    @include respond-to(small) {
      a {
        &:hover {
          color: #ff2e54;
        }
      }
    }
  }
}