@import '../util';

$enterHeight: 80px;

.landing {
  width: 100%;
  height: 100%;
  background-color: black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 100%;
    overflow: hidden;
  }

  &__link {
    color: white;
    max-height: calc(100% - #{$enterHeight});

    &:hover {
      color: #ff2e54;
    }
  }

  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__enter {
    color: white;
    text-align: center;
    @extend %heading-2;
    flex: 0 0 $enterHeight;
    margin-bottom: 0;
  }
}