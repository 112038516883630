$desktop-gutter: 40px;
$mobile-gutter: 20px;

$mobile-nav-height: 90px;

$nav-inner-width: 225px;
$nav-width: $nav-inner-width + 2*$desktop-gutter;
$nav-height: 680px;
$nav-height-small: 560px;
$nav-height-extra-small: 520px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == "small" {
    @media (min-width: 767px) {
      @content;
    }
  }

  @else if $breakpoint == "medium" {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $breakpoint == "large" {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @else if $breakpoint == "xlarge" {
    @media (min-width: 1600px) {
      @content;
    }
  }
}

.content-block {
  display: flex;
  flex-direction: column;

  &--reverse {
    flex-direction: column-reverse;
  }

  @include respond-to(small) {
    flex-direction: row;
    margin: 0 -20px;
  }

  &__body,
  &__image {
    flex: 0 1 100%;

    @include respond-to(small) {
      flex-basis: 50%;
      padding: 0 20px;
    }
  }
  
  &__image {
    text-align: center;

  }
}

%heading-1 {
  font-family: "Bodoni 72 Smallcaps", IowanOldStyleBTPro-Roman, Lato, serif;
  font-size: 3.375rem;
  letter-spacing: -.1rem;
  line-height: 3.375rem;
  font-weight: 400;
}

%heading-2 {
  font-family: "Bodoni 72 Smallcaps", IowanOldStyleBTPro-Roman, Lato, serif;
  font-size: 2.375rem;
  letter-spacing: -.05rem;
  line-height: 2.875rem;
  font-weight: 400;
  margin-bottom: $mobile-gutter;

  @include respond-to(small) {
    line-height: 3.875rem;
  }
}