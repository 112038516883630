/**
 * @license
 * MyFonts Webfont Build ID 3542913, 2018-03-16T02:23:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: IowanOldStyleBTPro-Roman by Bitstream
 * URL: https://www.myfonts.com/fonts/bitstream/iowan-old-style/pro-roman/
 * Copyright: Copyright 1990-2005 Bitstream Inc.  All rights reserved.
 * Licensed pageviews: 10,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3542913
 * 
 * © 2018 MyFonts Inc
*/

@font-face {
  font-family: 'IowanOldStyleBTPro-Roman';
  src: url('https://assets.tomraffin.com/fonts/iowan-old-style/360F81_0_0.eot');
  src: url('https://assets.tomraffin.com/fonts/iowan-old-style/360F81_0_0.eot?#iefix') format('embedded-opentype'),
       url('https://assets.tomraffin.com/fonts/iowan-old-style/360F81_0_0.woff2') format('woff2'),
       url('https://assets.tomraffin.com/fonts/iowan-old-style/360F81_0_0.woff') format('woff'),
       url('https://assets.tomraffin.com/fonts/iowan-old-style/360F81_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Bodoni 72 Smallcaps';
  src: url('https://assets.tomraffin.com/fonts/bodoni/smallcaps.woff2') format('woff2'),
       url('https://assets.tomraffin.com/fonts/bodoni/smallcaps.woff') format('woff'),
       url('https://assets.tomraffin.com/fonts/bodoni/smallcaps.ttf') format('truetype');
}
