@import '../util';

.bio {
  padding: $mobile-gutter;
  background-color: white;

  @include respond-to(small) {
    padding: $desktop-gutter;
  }

  h1 {
    @extend %heading-2;
  }
}